<template>
  <div class="space-y-10">
    <!-- pageTitle -->
    <div class="pageTitle">
      <h1>{{ $route.name }}</h1>
      <h3>{{ $route.meta.description }}</h3>
      <p class="font-semibold text-red-600">
        Prices valid for events registered as of May 1,
        {{ new Date().getFullYear() }}. Events registered with SAV on or before
        April 30, {{ new Date().getFullYear() - 1 }} will utilize previous
        price.
      </p>
    </div>
    <!-- search && buttons -->
    <div class="grid grid-cols-1 gap-10 sm:gap-12 sm:grid-cols-2">
      <!-- search -->
      <div class="order-2 sm:order-1">
        <label for="search" class="block text-sm font-medium"
          >Search Package Guide</label
        >
        <div class="mt-2 relative">
          <input
            v-model.trim="search"
            type="text"
            name="search"
            id="search"
            class="
              shadow-sm
              focus:ring-blue-500 focus:border-blue-500
              block
              w-full
              sm:text-sm
              border-gray-300
              rounded-md
              bg-gray-100
            "
            placeholder="Ireland, Hawaii, 4D100, SAV136"
          />
          <!-- clear search button -->
          <div class="absolute right-4 text-sm" style="top: 5px" v-if="search">
            <button
              @click="search = ''"
              class="
                cursor-pointer
                hover:bg-gray-600 hover:bg-opacity-25
                transition
                rounded-full
                h-7
                w-7
                flex
                items-center
                justify-center
                text-center text-gray-500
              "
            >
              <i
                class="mdi mdi-close text-xl"
                style="margin-left: 1px; margin-top: 1px"
              ></i>
            </button>
          </div>
        </div>
        <p class="mt-3 text-sm text-gray-500">
          Search destinations or package code
        </p>
      </div>
      <!-- print pdf -->
      <div class="flex items-center sm:justify-end order-1 sm:order-2">
        <button
          @click="onPdf"
          class="
            uppercase
            bg-blue-600
            text-blue-50
            hover:bg-blue-700
            font-semibold
            cursor-pointer
            text-sm
            tracking-wide
            transition
            rounded-full
            px-4
            py-2
            space-x-2
            inline-flex
            items-center
            justify-between
          "
        >
          <span>Print Package Guide PDF</span>
          <i class="mdi mdi-file-pdf-box text-xl"></i>
        </button>
      </div>
    </div>
    <!-- items -->
    <div
      v-if="filteredItems.length"
      class="
        shadow
        overflow-hidden
        sm:rounded-md
        divide-y divide-gray-200
        border border-gray-200
        text-sm text-gray-500
      "
    >
      <!-- rows -->
      <div v-for="(item, index) in filteredItems" :key="index">
        <div
          class="
            flex flex-col
            md:flex-row
            gap-6
            cursor-pointer
            hover:bg-gray-100
            transition
            px-6
            py-4
          "
          @click="item.showMoreInfo = !item.showMoreInfo"
        >
          <!-- packageCode && destination -->
          <div class="space-y-2 sm:w-1/3">
            <div class="uppercase font-semibold">{{ item.packageCode }}</div>
            <div class="text-blue-600 font-medium space-x-1 flex items-center">
              <i class="mdi mdi-map-marker text-xl"></i>
              <span>{{ item.destination }}</span>
            </div>
          </div>

          <!-- roomType && numberNights -->
          <div class="sm:w-1/4">
            <div class="space-x-2 flex items-center">
              <i class="mdi mdi-bed text-xl"></i>
              <span>{{ item.roomType.title }}</span>
            </div>
            <div class="space-x-2 flex items-center">
              <i class="mdi mdi-weather-night text-xl"></i>
              <span>{{ item.numberNights }} Nights</span>
            </div>
          </div>

          <!-- costToOrg && maxRetail -->
          <div class="space-y-2 sm:w-1/3 pt-1">
            <div v-if="$store.state.selectedPartner.displayCostToOrg">
              <span>Cost To Org ${{ item.costToOrg }}.00</span>
            </div>
            <div>
              <span>Max Retail Value ${{ item.maxRetail }}.00</span>
            </div>
          </div>

          <!-- view more info button -->
          <div class="grid place-items-center">
            <button
              @click.stop="item.showMoreInfo = !item.showMoreInfo"
              class="
                cursor-pointer
                hover:bg-gray-500 hover:bg-opacity-25
                transition
                rounded-full
                h-10
                w-10
                flex
                items-center
                justify-center
              "
            >
              <i
                class="mdi mdi-chevron-down text-xl transform"
                :class="!item.showMoreInfo ? 'rotate-0' : '-rotate-180'"
              ></i>
            </button>
          </div>
        </div>
        <!-- more info -->
        <transition name="fade">
          <div
            class="
              px-6
              py-4
              mt-2
              mb-2
              flex-col
              sm:flex
              lg:flex-row
              items-start
              justify-between
              lg:items-center
            "
            v-show="item.showMoreInfo"
          >
            <!-- info -->
            <div class="sm:mr-12">
              <!-- title -->
              <div v-html="item.title" class="font-semibold text-lg" />
              <!-- subtitle -->
              <div v-html="item.subtitle" class="font-medium" />
            </div>
            <!-- buttons -->
            <div
              class="
                mt-7
                flex flex-col
                justify-center
                gap-5
                sm:flex-row
                items-center
                lg:mt-0
              "
            >
              <router-link
                :to="
                  '/' +
                  $store.state.selectedPartner.id +
                  '/p/' +
                  item.packageCode
                "
                class="
                  uppercase
                  bg-gray-600
                  text-gray-50
                  hover:bg-gray-700
                  font-semibold
                  cursor-pointer
                  text-sm
                  tracking-wide
                  transition
                  rounded-full
                  px-4
                  py-3
                  space-x-2
                  inline-flex
                  items-center
                  justify-center
                  text-center
                "
              >
                <span>View Package</span>
              </router-link>
              <button
                @click="onPdf"
                class="
                  uppercase
                  bg-blue-600
                  text-blue-50
                  hover:bg-blue-700
                  font-semibold
                  cursor-pointer
                  text-sm
                  tracking-wide
                  transition
                  rounded-full
                  px-4
                  py-3
                  space-x-2
                  inline-flex
                  items-center
                  justify-center
                  text-center
                "
              >
                <span>Print Package PDF</span>
              </button>
              <button
                @click="
                  $store.state.youTubeModal = true;
                  $store.state.youTubeItem = item;
                "
                class="
                  uppercase
                  bg-red-600
                  text-red-50
                  hover:bg-red-700
                  font-semibold
                  cursor-pointer
                  text-sm
                  tracking-wide
                  transition
                  rounded-full
                  px-4
                  py-3
                  space-x-2
                  inline-flex
                  items-center
                  justify-center
                  text-center
                "
              >
                <span>Watch Package Video</span>
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- no filtered items -->
    <div v-if="this.search && !filteredItems.length">
      <p class="font-medium text-red-500">
        Sorry, there are no packages meeting that search criteria. Please enter
        a new search, or remove the search text.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [],
    search: "",
  }),
  methods: {
    createItems() {
      if (this.items.length) return;
      // adding 'showMoreInfo' to each $store item
      this.$store.state.retailPackages.forEach((i) => {
        i.showMoreInfo = false;
        this.items.push(i);
      });
    },
    onPdf() {
      if (this.$store.state.user) {
        window.open(this.item.pdfDownloadUrl, "_blank").focus();
      } else {
        this.$store.state.authModal.display = true;
        this.$store.state.authModal.action = "signup";
      }
    },
  },
  computed: {
    filteredItems() {
      if (this.search) {
        let searchText = this.search.toLowerCase();
        return this.items.filter((i) => {
          let item = {
            packageCode: i.packageCode.toLowerCase(),
            destination: i.destination.toLowerCase(),
          };
          return (
            item.packageCode.match(searchText) ||
            item.destination.match(searchText)
          );
        });
      }
      return this.items;
    },
  },
  watch: {
    "$store.state.retailPackages": {
      immediate: true,
      deep: true,
      handler(items) {
        if (!items.length) return;
        return this.createItems();
      },
    },
  },
};
</script>